import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@rmwc/circular-progress';
import { Redirect } from 'react-router-dom';
import { AppSyncClient } from '../../util/appsync';
import { Logo } from '../../components/Logo';
import queryString from 'query-string';
import { v4 as uuid } from 'uuid';
import { Game } from '../../API';

import { Button } from '@rmwc/button';
import { Card, CardActions, CardActionIcons } from '@rmwc/card';
import { SnackbarQueue, createSnackbarQueue } from '@rmwc/snackbar';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';

const snackbarQueue = createSnackbarQueue();

const AddPlayer: React.FC<{ location: { search: string } }> = props => {
  const [playerNameInput, setPlayerNameInput] = useState<string | undefined>(undefined);
  const [playerId, setPlayerId] = useState<string | undefined>(undefined);
  const [addingPlayer, setAddingPlayer] = useState<boolean>(false);

  // make sure user has a session id, using insecure localStorage for now
  useEffect(() => {
    let sessionId: string | null = window.localStorage.getItem('vacuum.sessionId');
    if (!sessionId) {
      sessionId = uuid();
      window.localStorage.setItem('vacuum.sessionId', sessionId);
    }
  }, []);

  const { location } = props;
  const values = queryString.parse(location.search);
  const { gameId } = values;

  const doAddPlayer = (): void => {
    if (!addingPlayer) {
      setAddingPlayer(true);
      const sessionId: string | null = window.localStorage.getItem('vacuum.sessionId');
      AppSyncClient.mutateAddPlayer(gameId as string, playerNameInput as string, sessionId || 'NO_SESSION')
        .then((game: Game) => {
          console.log(game);
          const players = game.players;
          const playerId = players[players.length - 1].id;
          setPlayerId(playerId);
        })
        .catch(error => {
          console.log(`ERROR: ${JSON.stringify(error)}`);
          snackbarQueue.notify({
            title: <Typography use="caption">There was an error, you may need to reload the page:</Typography>,
            body: <>{JSON.stringify(error)}</>,
            icon: 'error',
            dismissIcon: true,
            stacked: false,
          });
        });
    }
  };

  if (playerId) {
    return <Redirect to={`/play?gameId=${gameId}&playerId=${playerId}`} />;
  }

  if (addingPlayer) {
    return <CircularProgress />;
  }

  return (
    <div className="config-panel">
      <SnackbarQueue messages={snackbarQueue.messages} stacked />
      <div className="logo spaced">
        <Logo />
      </div>
      <Card>
        <Typography use="overline">What is your name?</Typography>
        <TextField
          label="Name"
          value={playerNameInput}
          onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>): void => {
            event.which === 13 && doAddPlayer();
          }}
          onChange={(event: React.FormEvent<HTMLInputElement>): void => {
            console.log(event);
            setPlayerNameInput(event.currentTarget.value);
          }}
        />
        <CardActions>
          <CardActionIcons>
            <Button raised onClick={doAddPlayer}>
              Ok
            </Button>
          </CardActionIcons>
        </CardActions>
      </Card>
    </div>
  );
};

export { AddPlayer };
