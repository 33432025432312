// tslint:disable
// this is an auto generated file. This will be overwritten

export const updatedGame = `subscription UpdatedGame($id: ID!) {
  updatedGame(id: $id) {
    id
    version
    created
    code
    data
  }
}
`;
export const newMessage = `subscription NewMessage($gameId: ID!) {
  newMessage(gameId: $gameId) {
    gameId
    to
    from
    created
    message
  }
}
`;
