// tslint:disable
// this is an auto generated file. This will be overwritten

export const getGame = `query GetGame($id: ID!) {
  getGame(id: $id) {
    id
    version
    created
    code
    data
  }
}
`;
export const getGameIdFromCode = `query GetGameIdFromCode($code: String!) {
  getGameIdFromCode(code: $code)
}
`;
export const getMessages = `query GetMessages($gameId: ID!, $nextToken: String) {
  getMessages(gameId: $gameId, nextToken: $nextToken) {
    messages {
      gameId
      to
      from
      created
      message
    }
    nextToken
  }
}
`;
