import React, { useEffect } from 'react';
import { AppSyncClient } from '../../util/appsync';
import { Select } from '@rmwc/select';
import { TextField } from '@rmwc/textfield';
import { Typography } from '@rmwc/typography';
import { GetMessagesQuery, Player } from '../../API';

type Message = GetMessagesQuery['getMessages']['messages'][0];

const sendMessage = (gameId: string, message: string, to: string, from: string): void => {
  AppSyncClient.mutateCreateMessage(gameId, to, from, message);
  // TODO: handle errors
};

const MessageBubble: React.FC<{ playerName: string; message: Message; ownPlayerId: string }> = props => {
  const message = props.message;
  const playerName = props.playerName;
  const ownPlayerId = props.ownPlayerId;

  return (
    <div className={`message-bubble ${message.from === ownPlayerId ? 'own-message-bubble' : 'other-message-bubble'}`}>
      {message.from !== ownPlayerId && (
        <div>
          <Typography use="caption">{playerName}</Typography>
        </div>
      )}
      <div>{message.message}</div>
    </div>
  );
};

const MessagesView: React.FC<{
  gameId: string;
  playerId: string;
  players: Player[];
  messages: Message[];
  selectedPlayerId: string;
  setSelectedPlayerId: (id: string) => void;
  messageDraft: string;
  setMessageDraft: (id: string) => void;
}> = props => {
  const players = props.players;
  const playerId = props.playerId;
  const gameId = props.gameId;
  const messages = props.messages;
  const selectedPlayerId = props.selectedPlayerId;
  const setSelectedPlayerId = props.setSelectedPlayerId;
  const messageDraft = props.messageDraft;
  const setMessageDraft = props.setMessageDraft;

  useEffect(() => {
    const messagesList = document.getElementById('messages-list');
    if (messagesList) {
      messagesList.scrollTop = messagesList.scrollHeight;
    }
  }, [messages]);

  type PlayerOption = { label: string; value: string };

  const getPlayerName = (findPlayerId: string): string => {
    const foundPlayer = players.find(thisPlayer => thisPlayer.id === findPlayerId);
    return foundPlayer ? foundPlayer.name : 'UNKNOWN';
  };

  const getPlayerOptions = (): PlayerOption[] => {
    // start with 'Everyone' option
    const options: PlayerOption[] = [
      {
        label: 'Everyone',
        value: 'all',
      },
    ];

    // add options for each player that isn't current player
    players
      .filter(thisPlayer => thisPlayer.id !== playerId)
      .forEach(thisPlayer =>
        options.push({
          label: thisPlayer.name,
          value: thisPlayer.id,
        }),
      );

    return options;
  };

  const filteredMessages = messages.filter(message => {
    if (selectedPlayerId === 'all') {
      return message.to === 'all';
    } else {
      return (
        (message.from === playerId && message.to === selectedPlayerId) ||
        (message.from === selectedPlayerId && message.to === playerId)
      );
    }
  });

  return (
    <div className="messages-body">
      <div id="messages-list" className="messages-list">
        {filteredMessages.map(message => (
          <MessageBubble
            key={message.created}
            playerName={getPlayerName(message.from)}
            message={message}
            ownPlayerId={playerId}
          />
        ))}
      </div>
      <div className="messages-controls">
        <div>
          <Select
            label="Send to"
            placeholder="Everyone"
            value={selectedPlayerId}
            onChange={(event): void => setSelectedPlayerId(event.currentTarget.value)}
            options={getPlayerOptions()}
          />
        </div>
        <TextField
          label={`Type message here...`}
          value={messageDraft}
          onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>): void => {
            if (event.which === 13) {
              setMessageDraft('');
              sendMessage(gameId, messageDraft, selectedPlayerId, playerId);
            }
          }}
          onChange={(event: React.FormEvent<HTMLInputElement>): void => {
            setMessageDraft(event.currentTarget.value);
          }}
          trailingIcon={{
            icon: 'send',
            tabIndex: 0,
            onClick: (): void => {
              sendMessage(gameId, messageDraft, selectedPlayerId, playerId);
              setMessageDraft('');
            },
          }}
        />
      </div>
    </div>
  );
};

export { MessagesView };
