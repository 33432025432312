import { GameStatus, Team, RoomType, Component, Sacrifice, RoomStatus } from '../../API';

const friendlyRoomStatus = (status: RoomStatus): string => {
  switch (status) {
    case RoomStatus.DAMAGED:
      return 'damaged';
    case RoomStatus.LOCKED:
      return 'locked';
    default:
      return status;
  }
};

const friendlyAuctionType = (auctionType: Component | Sacrifice): string => {
  switch (auctionType) {
    case Component.TOOL:
      return 'tool';
    case Component.STEALTH:
      return 'stealth';
    case Component.STRENGTH:
      return 'strength';
    case Component.PARTS:
      return 'parts';
    case Sacrifice.TIRED:
      return 'tired';
    case Sacrifice.CLUMSY:
      return 'clumsy';
    case Sacrifice.FOCUSED:
      return 'focused';
    case Sacrifice.DISTRACTED:
      return 'distracted';
    case Sacrifice.HUNGRY:
      return 'hungry';
    case Sacrifice.SHITS:
      return 'the runs';
    default:
      return auctionType;
  }
};

const friendlyRoomName = (room: RoomType): string => {
  switch (room) {
    case RoomType.BRIDGE:
      return 'The Bridge';
    case RoomType.ARMORY:
      return 'The Armory';
    case RoomType.ELECTRICAL:
      return 'Electrical';
    case RoomType.ENGINEERING:
      return 'Engineering';
    case RoomType.COMMUNICATIONS:
      return 'Communications';
    case RoomType.HEAD:
      return 'The Head';
    case RoomType.CANTEEN:
      return 'The Canteen';
    case RoomType.MAINTENANCE:
      return 'Maintenance';
    default:
      return room;
  }
};

const friendlyTeamName = (team: Team): string => {
  switch (team) {
    case Team.CREW:
      return 'The Crew';
    case Team.TRAITOR:
      return 'Traitors';
    default:
      return team;
  }
};

const friendlyGameStatus = (gameStatus: GameStatus): string => {
  switch (gameStatus) {
    case GameStatus.CREW_WON:
      return 'The Crew won';
    case GameStatus.TRAITORS_WON:
      return 'Traitors won';
    default:
      return gameStatus;
  }
};

export const EnumHelper = {
  friendlyRoomName,
  friendlyTeamName,
  friendlyGameStatus,
  friendlyAuctionType,
  friendlyRoomStatus,
};
