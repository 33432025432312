import React from 'react';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { ListDivider } from '@rmwc/list';
import { ComponentAuction, SacrificeAuction, Player } from '../../API';
import { EnumHelper } from '../../util/enumhelper';

const Auctions: React.FC<{
  componentAuctions: ComponentAuction[];
  sacrificeAuctions: SacrificeAuction[];
  placeBid: (index: number, value: number, isSacrifice: boolean) => void;
  players: Player[];
  currentPlayerId: string;
  creditsAvailable: number;
}> = props => {
  const { componentAuctions, placeBid, players, currentPlayerId, creditsAvailable } = props;

  const creditsUsed = componentAuctions
    .filter(auction => auction.winningPlayerId === currentPlayerId)
    .map(auction => auction.currentBid)
    .reduce((a, b) => a + b, 0);

  const getPlayerName = (playerId: string): string => {
    const player = players.find(player => player.id === playerId);
    return player ? player.name : playerId;
  };

  const getWinnerText = (winningPlayerId: string | null | undefined): string => {
    if (winningPlayerId === currentPlayerId) {
      return 'You';
    }

    if (winningPlayerId && winningPlayerId !== 'nobody') {
      return getPlayerName(winningPlayerId);
    } else {
      return '';
    }
  };

  const AuctionBox: React.FC<{
    auction: ComponentAuction | SacrificeAuction;
    isSacrifice: boolean;
    index: number;
  }> = props => {
    const { auction, isSacrifice, index } = props;
    const currentPlayerIsWinning: boolean = auction.winningPlayerId === currentPlayerId;
    const nextBid = currentPlayerIsWinning
      ? auction.currentBid
      : isSacrifice
      ? auction.currentBid - 1
      : auction.currentBid + 1;

    const disabled = isSacrifice
      ? currentPlayerIsWinning
      : currentPlayerIsWinning || creditsUsed + nextBid > creditsAvailable;

    return (
      <div
        className={`auction-box ${isSacrifice ? 'sacrifice-auction' : 'component-auction'} ${
          currentPlayerIsWinning ? 'winning' : ''
        }`}
      >
        <div>
          <Button raised disabled={disabled} onClick={(): void => placeBid(index, nextBid, isSacrifice)}>
            {isSacrifice && '+'}${nextBid}
          </Button>
          {EnumHelper.friendlyAuctionType(auction.type)}
        </div>
        <div className="auction-notes">{auction.notes}</div>
        <div className="auction-winner">{getWinnerText(auction.winningPlayerId)}</div>
      </div>
    );
  };

  return (
    <Card>
      <div>
        <div>
          <Typography use="headline6">Auctions</Typography>
        </div>
        <div>
          <Typography use="subtitle2">
            Credits available: {creditsAvailable - creditsUsed} / {creditsAvailable}
          </Typography>
        </div>
      </div>
      <ListDivider />
      {componentAuctions.map((auction, index) => (
        <AuctionBox key={`component-auction-${index}`} index={index} auction={auction} isSacrifice={false} />
      ))}
      {/*
      <ListDivider />
      {sacrificeAuctions.map((auction, index) => (
        <AuctionBox key={`sacrifice-auction-${index}`} index={index} auction={auction} isSacrifice={true} />
      ))}
      */}
    </Card>
  );
};

export { Auctions };
