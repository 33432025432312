import React from 'react';
import { Card } from '@rmwc/card';
import { EnumHelper } from '../../util/enumhelper';
import { Icon } from '@rmwc/icon';
import { ListDivider } from '@rmwc/list';
import { Typography } from '@rmwc/typography';
import { Player, PlayerStatus, Team } from '../../API';

// TODO: this looks a lot like Location, maybe we can share some code/css
const PlayerInfos: React.FC<{
  players: Player[];
  currentPlayer: Player;
  setAirlockPlayer: (playerId: string, airlock: boolean) => void;
}> = props => {
  const { players, currentPlayer, setAirlockPlayer } = props;
  const canSeeTeam = (player: Player): boolean =>
    currentPlayer.id === player.id || currentPlayer.team === Team.TRAITOR || player.status !== PlayerStatus.ALIVE;

  return (
    <div className="player-infos">
      {players.map(player => {
        const airlockPlayer = currentPlayer.nextTurn.airlockList.find(thisPlayerId => thisPlayerId === player.id);
        const onClick =
          player.id === currentPlayer.id
            ? (): void => {}
            : (): void => {
                setAirlockPlayer(player.id, !airlockPlayer);
              };
        return (
          <div key={player.id} className="player-info-row">
            <span className={`player-info-airlock`}>{airlockPlayer && <Icon icon="exit_to_app" />}</span>
            <span
              className={`player-info ${player.id === currentPlayer.id ? 'current-player-info' : ''}`}
              onClick={onClick}
            >
              <span className="player-info-ready">
                <Icon icon={player.ready ? 'check_box' : 'check_box_outline_blank'} />
              </span>
              <span className={`player-info-name ${player.status !== PlayerStatus.ALIVE ? 'dead' : ''}`}>
                {player.name}
              </span>
              <span className="player-info-middle"></span>
              <span className="player-info-statuses">
                {canSeeTeam(player) && EnumHelper.friendlyTeamName(player.team)}
              </span>
            </span>
          </div>
        );
      })}
    </div>
  );
};

const Players: React.FC<{
  players: Player[];
  currentPlayer: Player;
  setAirlockPlayer: (playerId: string, airlock: boolean) => void;
}> = props => {
  const { players, currentPlayer, setAirlockPlayer } = props;
  return (
    <Card>
      <div>
        <Typography use="headline6">Players</Typography>
      </div>
      <div>
        <Typography use="subtitle2">
          Click on a player to vote them out the airlock{' '}
          <span className="icon-vertical-shift">
            <Icon icon="exit_to_app" />
          </span>
        </Typography>
      </div>
      <ListDivider />
      <PlayerInfos players={players} currentPlayer={currentPlayer} setAirlockPlayer={setAirlockPlayer} />
    </Card>
  );
};

export { Players };
