/* tslint:disable */
//  This file was automatically generated and should not be edited.

export type Turn = {
  nextAct: Act;
  nextRoom?: RoomType | null;
  airlockList: Array<string>;
  bid?: Bid | null;
};

export enum Act {
  NONE = 'NONE',
  MOVE = 'MOVE',
  JUMP = 'JUMP',
  REPAIR = 'REPAIR',
  SABOTAGE = 'SABOTAGE',
  TRAP = 'TRAP',
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
  SCAN = 'SCAN',
}

export enum RoomType {
  BRIDGE = 'BRIDGE',
  ARMORY = 'ARMORY',
  ELECTRICAL = 'ELECTRICAL',
  ENGINEERING = 'ENGINEERING',
  MAINTENANCE = 'MAINTENANCE',
  COMMUNICATIONS = 'COMMUNICATIONS',
  CANTEEN = 'CANTEEN',
  HEAD = 'HEAD',
}

export type Bid = {
  isSacrifice: boolean;
  index: number;
  amount: number;
};

export type GameConfig = {
  numCrew: number;
  numTraitors: number;
  initialDistanceToHome: number;
  initialDistanceFromPirates: number;
  numComponentAuctions: number;
  numSacrificeAuctions: number;
};

export type Game = {
  id: string;
  code: string;
  version: number;
  turn: number;
  created: number;
  lastUpdated: number;
  players: Array<Player>;
  rooms: Array<Room>;
  distanceToHome: number;
  distanceFromPirates: number;
  status: GameStatus;
  turnSummary: Array<string>;
  componentAuctions: Array<ComponentAuction>;
  sacrificeAuctions: Array<SacrificeAuction>;
  config: GameConfig;
};

export type Player = {
  id: string;
  sessionId: string;
  name: string;
  team: Team;
  room: RoomType;
  status: PlayerStatus;
  ready: boolean;
  credits: number;
  components: Array<Component>;
  nextTurn: Turn;
  turnSummary: Array<string>;
};

export enum Team {
  CREW = 'CREW',
  TRAITOR = 'TRAITOR',
}

export enum PlayerStatus {
  ALIVE = 'ALIVE',
  JUMPED = 'JUMPED',
  AIRLOCKED = 'AIRLOCKED',
  EXPLODED = 'EXPLODED',
}

export enum Component {
  TOOL = 'TOOL',
  STEALTH = 'STEALTH',
  STRENGTH = 'STRENGTH',
  PARTS = 'PARTS',
}

export type Room = {
  type: RoomType;
  statuses: Array<RoomStatus>;
};

export enum RoomStatus {
  DAMAGED = 'DAMAGED',
  LOCKED = 'LOCKED',
}

export enum GameStatus {
  PLAYING = 'PLAYING',
  CREW_WON = 'CREW_WON',
  TRAITORS_WON = 'TRAITORS_WON',
}

export type ComponentAuction = {
  type: Component;
  notes: string;
  currentBid: number;
  winningPlayerId?: string | null;
};

export type SacrificeAuction = {
  type: Sacrifice;
  notes: string;
  currentBid: number;
  winningPlayerId: string;
};

export enum Sacrifice {
  TIRED = 'TIRED',
  CLUMSY = 'CLUMSY',
  FOCUSED = 'FOCUSED',
  DISTRACTED = 'DISTRACTED',
  HUNGRY = 'HUNGRY',
  SHITS = 'SHITS',
}

export type AddTurnMutationVariables = {
  gameId: string;
  playerId: string;
  ready: boolean;
  nextTurn: Turn;
};

export type AddTurnMutation = {
  addTurn: {
    __typename: 'CompressedGame';
    id: string;
    version: number;
    created: number;
    code: string;
    data: string;
  };
};

export type StartGameMutationVariables = {
  gameId: string;
};

export type StartGameMutation = {
  startGame: {
    __typename: 'CompressedGame';
    id: string;
    version: number;
    created: number;
    code: string;
    data: string;
  };
};

export type AddPlayerMutationVariables = {
  gameId: string;
  playerName: string;
  sessionId: string;
};

export type AddPlayerMutation = {
  addPlayer: {
    __typename: 'CompressedGame';
    id: string;
    version: number;
    created: number;
    code: string;
    data: string;
  };
};

export type CreateGameMutationVariables = {
  config: GameConfig;
};

export type CreateGameMutation = {
  createGame: {
    __typename: 'CompressedGame';
    id: string;
    version: number;
    created: number;
    code: string;
    data: string;
  };
};

export type CreateGameCodeMutationVariables = {
  gameId: string;
};

export type CreateGameCodeMutation = {
  createGameCode: string;
};

export type CompressGameMutationVariables = {
  game?: Game | null;
};

export type CompressGameMutation = {
  // this is weird, we don't use use, but need something that refereces Game in order for the associated types to be code-generated
  compressGame: {
    __typename: 'CompressedGame';
    id: string;
    version: number;
    created: number;
    code: string;
    data: string;
  };
};

export type CreateMessageMutationVariables = {
  gameId: string;
  to: string;
  from: string;
  message: string;
};

export type CreateMessageMutation = {
  createMessage: {
    __typename: 'Message';
    gameId: string;
    to: string;
    from: string;
    created: string;
    message: string;
  };
};

export type GetGameQueryVariables = {
  id: string;
};

export type GetGameQuery = {
  getGame: {
    __typename: 'CompressedGame';
    id: string;
    version: number;
    created: number;
    code: string;
    data: string;
  };
};

export type GetGameIdFromCodeQueryVariables = {
  code: string;
};

export type GetGameIdFromCodeQuery = {
  getGameIdFromCode: string;
};

export type GetMessagesQueryVariables = {
  gameId: string;
  nextToken?: string | null;
};

export type GetMessagesQuery = {
  getMessages: {
    __typename: 'PaginatedMessages';
    messages: Array<{
      __typename: 'Message';
      gameId: string;
      to: string;
      from: string;
      created: string;
      message: string;
    }>;
    nextToken: string | null;
  };
};

export type UpdatedGameSubscriptionVariables = {
  id: string;
};

export type UpdatedGameSubscription = {
  updatedGame: {
    __typename: 'CompressedGame';
    id: string;
    version: number;
    created: number;
    code: string;
    data: string;
  } | null;
};

export type NewMessageSubscriptionVariables = {
  gameId: string;
};

export type NewMessageSubscription = {
  newMessage: {
    __typename: 'Message';
    gameId: string;
    to: string;
    from: string;
    created: string;
    message: string;
  } | null;
};
