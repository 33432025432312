import React from 'react';
import { Icon } from '@rmwc/icon';

import { Game } from '../../API';

const PlayGameBar: React.FC<{
  game: Game;
  setProfileOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRulesOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ready: boolean;
  readyChanged: (isReady: boolean) => void;
}> = props => {
  const { game, setProfileOpen, setRulesOpen, setChatOpen, ready, readyChanged } = props;

  if (!game) {
    return <></>;
  }

  //<Checkbox checked={player.ready} onChange={(event): void => readyChanged(event.currentTarget.checked)} />
  return (
    <div className="game-bar">
      <div className="bar-button">
        <Icon onClick={(): void => readyChanged(!ready)} icon={`${ready ? 'check_box' : 'check_box_outline_blank'}`} />
      </div>
      <div className="bar-button">
        <Icon onClick={(): void => setChatOpen(true)} icon="message" />
      </div>
      <div className="bar-button">
        <Icon onClick={(): void => setProfileOpen(true)} icon="emoji_people" />
      </div>
      <div className="bar-button">
        <Icon onClick={(): void => setRulesOpen(true)} icon="help" />
      </div>
    </div>
  );
};

export { PlayGameBar };
