import React from 'react';
import { Card } from '@rmwc/card';
import { ListDivider } from '@rmwc/list';
import { Typography } from '@rmwc/typography';
import { Game } from '../../API';

const GameInfo: React.FC<{
  game: Game;
}> = props => {
  const { game } = props;

  return (
    <Card>
      <div>
        <Typography use="headline6">Game</Typography>
      </div>
      <div>
        <Typography use="subtitle2">Turn {game.turn}</Typography>
      </div>
      <ListDivider />
    </Card>
  );
};

export { GameInfo };
