// tslint:disable
// this is an auto generated file. This will be overwritten

export const addTurn = `mutation AddTurn(
  $gameId: ID!
  $playerId: String!
  $ready: Boolean!
  $nextTurn: Turn!
) {
  addTurn(
    gameId: $gameId
    playerId: $playerId
    ready: $ready
    nextTurn: $nextTurn
  ) {
    id
    version
    created
    code
    data
  }
}
`;
export const startGame = `mutation StartGame($gameId: ID!) {
  startGame(gameId: $gameId) {
    id
    version
    created
    code
    data
  }
}
`;
export const addPlayer = `mutation AddPlayer($gameId: ID!, $playerName: String!, $sessionId: ID!) {
  addPlayer(gameId: $gameId, playerName: $playerName, sessionId: $sessionId) {
    id
    version
    created
    code
    data
  }
}
`;
export const createGame = `mutation CreateGame($config: GameConfig!) {
  createGame(config: $config) {
    id
    version
    created
    code
    data
  }
}
`;
export const createGameCode = `mutation CreateGameCode($gameId: ID!) {
  createGameCode(gameId: $gameId)
}
`;
export const compressGame = `mutation CompressGame($game: Game) {
  compressGame(game: $game) {
    id
    version
    created
    code
    data
  }
}
`;
export const createMessage = `mutation CreateMessage($gameId: ID!, $to: ID!, $from: ID!, $message: String!) {
  createMessage(gameId: $gameId, to: $to, from: $from, message: $message) {
    gameId
    to
    from
    created
    message
  }
}
`;
