import React from 'react';
import { Card } from '@rmwc/card';
import { Icon } from '@rmwc/icon';
import { Typography } from '@rmwc/typography';
import { EnumHelper } from '../../util/enumhelper';
import { ListDivider } from '@rmwc/list';
import { PlayerStatus, Player, Room, RoomType, RoomStatus } from '../../API';

const RoomPlayers: React.FC<{
  roomPlayers: Player[];
}> = props => {
  const { roomPlayers } = props;

  return (
    <div>
      {roomPlayers.map(thisPlayer => (
        <span className="room-info-player" key={thisPlayer.id}>
          <Typography use="caption">
            {thisPlayer.status !== PlayerStatus.ALIVE && '💀 '}
            {thisPlayer.name}
          </Typography>
        </span>
      ))}
    </div>
  );
};

const StatusTags: React.FC<{
  statuses: RoomStatus[];
}> = props => {
  const { statuses } = props;
  return (
    <span>
      {statuses &&
        statuses.map(status => (
          <span key={status} className={`room-info-status room-info-status-${status.toLowerCase().replace('_', '-')}`}>
            <Typography use="caption">{EnumHelper.friendlyRoomStatus(status)}</Typography>
          </span>
        ))}
    </span>
  );
};

const RoomInfos: React.FC<{
  rooms: Room[];
  currentRoom: RoomType;
  nextRoom: RoomType | undefined;
  setNextRoom: (room: RoomType) => void;
}> = props => {
  const { rooms, currentRoom, nextRoom, setNextRoom } = props;
  return (
    <div className="room-infos">
      {rooms.map(room => {
        let arrowIcon = undefined;
        if (room.type === currentRoom && (!nextRoom || room.type === nextRoom)) {
          arrowIcon = 'code';
        } else if (room.type === nextRoom) {
          arrowIcon = 'keyboard_arrow_right';
        } else if (room.type === currentRoom) {
          arrowIcon = 'keyboard_arrow_left';
        }
        return (
          <div key={room.type} className="room-info-row">
            <span className={`room-info-arrow`}>{arrowIcon && <Icon icon={arrowIcon} />}</span>
            <span
              className={`room-info ${room.type === currentRoom ? 'current-room-info' : ''}`}
              onClick={(): void => setNextRoom(room.type)}
            >
              <span className="room-info-name">{EnumHelper.friendlyRoomName(room.type)}</span>
              <span className="room-info-middle"></span>
              <span className="room-info-statuses">
                <StatusTags statuses={room.statuses} />
              </span>
            </span>
          </div>
        );
      })}
    </div>
  );
};

const Location: React.FC<{
  currentPlayer: Player;
  nextRoom: RoomType | undefined;
  players: Player[];
  rooms: Room[];
  setNextRoom: (room: RoomType) => void;
}> = props => {
  const { currentPlayer, players, rooms, nextRoom, setNextRoom } = props;
  const roomPlayers = players.filter(player => player.room === currentPlayer.room);
  const currentRoom = rooms.find(room => room.type === currentPlayer.room);

  return (
    <Card>
      <div>
        <Typography use="headline6">{EnumHelper.friendlyRoomName(currentPlayer.room)}</Typography>
      </div>
      <div>
        <Typography use="subtitle2">
          Click on room to move there next turn{' '}
          <span className="icon-vertical-shift">
            <Icon icon="keyboard_arrow_right" />
          </span>
        </Typography>
      </div>
      <ListDivider />
      <div className="room-info-tags">
        <StatusTags statuses={currentRoom ? currentRoom.statuses : []} />
      </div>
      <div className="room-info-tags">
        <RoomPlayers roomPlayers={roomPlayers} />
      </div>
      <ListDivider />
      <RoomInfos rooms={rooms} currentRoom={currentPlayer.room} nextRoom={nextRoom} setNextRoom={setNextRoom} />
    </Card>
  );
};

export { Location };
