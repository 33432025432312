import { CreateGameMutation, Game } from '../API';
import pako from 'pako';

export type CompressedGame = CreateGameMutation['createGame'];

const compressGame = (game: Game): CompressedGame => {
  const id = game.id;
  const version = game.version;
  const created = game.created;
  const code = game.code;

  delete game.id;
  delete game.version;
  delete game.created;
  delete game.code;

  const buffer: Buffer = Buffer.from(pako.deflate(JSON.stringify(game)));
  const data: string = buffer.toString('base64');

  return {
    __typename: 'CompressedGame',
    id,
    version,
    created,
    code,
    data,
  };
};

const decompressGame = (compressedGame: CompressedGame): Game => {
  const buffer = Buffer.from(compressedGame.data, 'base64');
  const game = JSON.parse(pako.inflate(buffer, { to: 'string' }));

  game.id = compressedGame.id;
  game.version = compressedGame.version;
  game.created = compressedGame.created;
  game.code = compressedGame.code;

  return game;
};

export const CompressionHelper = {
  compressGame,
  decompressGame,
};
