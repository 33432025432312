import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Dead } from '../Dead';
import { Location } from '../Location';
import { PreGame } from '../PreGame';
import { Players } from '../Players';
import { PlayGameBar } from '../PlayGameBar';
import { Rules } from '../Rules';
import { AppSyncClient } from '../../util/appsync';
import { EnumHelper } from '../../util/enumhelper';
import { Button } from '@rmwc/button';
import { Card, CardActions, CardActionIcons } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { ListDivider } from '@rmwc/list';
import { Typography } from '@rmwc/typography';
import { SnackbarQueue, createSnackbarQueue } from '@rmwc/snackbar';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import queryString from 'query-string';
import { Player, PlayerStatus, Game, GameStatus, GetMessagesQuery, RoomType, Act, Bid } from '../../API';
import { Chat } from '../Chat';
import { Auctions } from '../Auctions';
import { GameInfo } from '../GameInfo';

type Message = GetMessagesQuery['getMessages']['messages'][0];

interface Props {
  location: {
    search: string;
  };
}

const snackbarQueue = createSnackbarQueue();

const showError = (errorType: string) => (error: object): void => {
  console.error(JSON.stringify(error));
  snackbarQueue.notify({
    title: <Typography use="caption">There was an error, you may need to reload the page:</Typography>,
    body: errorType,
    icon: 'error',
    dismissIcon: true,
    stacked: false,
  });
};

const TurnSummary: React.FC<{
  open: boolean;
  setTurnSummaryOpen: (open: boolean) => void;
  gameSummary: string[];
  playerSummary: string[];
}> = props => {
  const { open, setTurnSummaryOpen, gameSummary, playerSummary } = props;
  return (
    <Dialog
      open={open}
      onClose={(evt): void => {
        setTurnSummaryOpen(false);
      }}
    >
      <DialogTitle>Turn summary</DialogTitle>
      <DialogContent>
        {gameSummary && gameSummary.map(item => <div key={item}>{item}</div>)}
        <ListDivider />
        {playerSummary && playerSummary.map(item => <div key={item}>{item}</div>)}
      </DialogContent>
      <DialogActions>
        <DialogButton raised action="close">
          Ok
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

const RulesDialog: React.FC<{ open: boolean; setRulesOpen: (open: boolean) => void; gameCode: string }> = props => {
  const { open, setRulesOpen, gameCode } = props;
  return (
    <div className="rules-dialog">
      <Dialog
        open={open}
        onClose={(evt): void => {
          setRulesOpen(false);
        }}
      >
        <DialogTitle>Rules</DialogTitle>
        <DialogContent>
          <Rules />
        </DialogContent>
        <DialogActions>
          {gameCode.toUpperCase()}
          <a
            title="support@moonfetus.com"
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:support@moonfetus.com"
          >
            Send feedback
          </a>
          <DialogButton raised isDefaultAction action="close">
            Close
          </DialogButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const Profile: React.FC<{ player: Player; open: boolean; setProfileOpen: (open: boolean) => void }> = props => {
  const { player, open, setProfileOpen } = props;
  return (
    <Dialog
      open={open}
      onClose={(evt): void => {
        setProfileOpen(false);
      }}
    >
      <DialogTitle>{player.name}</DialogTitle>
      <DialogContent>
        <ListDivider />
        <div>Team: {EnumHelper.friendlyTeamName(player.team)}</div>
      </DialogContent>
      <DialogActions>
        <DialogButton raised action="close">
          Close
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

const PlayGame: React.FC<Props> = props => {
  const { location } = props;
  const values = queryString.parse(location.search);
  const gameId: string = values.gameId as string;
  const playerId: string = values.playerId as string;

  const [game, setGame] = useState<Game | undefined>(undefined);
  const [messages, setMessages] = useState<Message[]>([]);
  const [snackbarMessages, setSnackbarMessages] = useState<Set<Message>>(new Set());
  const [profileOpen, setProfileOpen] = React.useState(false);
  const [turnSummaryOpen, setTurnSummaryOpen] = React.useState(false);
  const [rulesOpen, setRulesOpen] = React.useState(false);
  const [chatOpen, setChatOpen] = React.useState(false);
  const [nextRoom, setNextRoom] = React.useState<RoomType | undefined>(undefined);

  // keeping this state up here so we can preserve it and use it for snackbar logic
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>('all');
  const [messageDraft, setMessageDraft] = useState<string>('');

  useEffect(() => {
    const getAndSubscribeGame = (): void => {
      AppSyncClient.queryGetGame(gameId)
        .then((response: Game) => {
          console.log('got initial game:');
          console.log(response);

          setGame(response);

          // FIXME: should we be unsubscribing somewhere?
          AppSyncClient.subscribeUpdatedGame(
            gameId,
            (newGame: Game) => {
              console.log('got updated game:');
              console.log(newGame);

              setGame(prev => {
                // if this is a new turn, show the turn summary
                if (newGame.status === GameStatus.PLAYING && prev && prev.turn < newGame.turn && newGame.turn > 1) {
                  setTurnSummaryOpen(true);
                }
                return newGame;
              });
            },
            showError('Error while updating game data'),
          );
        })
        .catch(showError('Error while loading game'));
    };

    const getAndSubscribeMessages = (): void => {
      AppSyncClient.queryGetMessages(gameId)
        .then(response => {
          console.log('got initial messages:');
          console.log(response.data.getMessages.messages);
          setMessages(response.data.getMessages.messages);

          // FIXME: should we be unsubscribing somewhere?
          AppSyncClient.subscribeNewMessage(
            gameId,
            response => {
              console.log('got updated message:');
              console.log(response.data.newMessage);

              if (response.data) {
                setMessages(prev => {
                  if (response.data.newMessage) {
                    prev.push(response.data.newMessage);
                  }
                  return Array.from(prev);
                });

                // see note later, this is kind of a goofy way of doing what we need to do
                setSnackbarMessages(prev => {
                  return response.data.newMessage ? new Set(prev.add(response.data.newMessage)) : prev;
                });
              }
            },
            showError('Error while updating message data'),
          );
        })
        .catch(showError('Error while loading messages'));
    };

    getAndSubscribeGame();
    getAndSubscribeMessages();
  }, [gameId]);

  if (!game) {
    return <CircularProgress />;
  }

  if (!playerId) {
    const sessionId: string | null = window.localStorage.getItem('vacuum.sessionId');
    if (sessionId) {
      const thisPlayer = game.players.find(player => player.sessionId === sessionId);
      if (thisPlayer) {
        return <Redirect to={`/play?gameId=${gameId}&playerId=${thisPlayer.id}`} />;
      } else {
        if (game.turn === 0) {
          return <Redirect to={`/addPlayer?gameId=${gameId}`} />;
        } else {
          return <>wut?</>;
        }
        // TODO: should we just let them choose their player?
      }
    }
  }

  const player = game.players.find(player => {
    return player.id === playerId;
  });
  if (!player) {
    return <h1>player not found</h1>;
  }

  if (game.turn === 0) {
    return <PreGame game={game} />;
  } else if (player.status !== PlayerStatus.ALIVE) {
    return <Dead airlocked={player.status === PlayerStatus.AIRLOCKED} />;
  } else {
    // show snackbars
    // FIXME: this is gross, we do this so we can use the game state to show the snackbar, maybe there is a cleaner way to do this
    if (snackbarMessages.size > 0) {
      snackbarMessages.forEach(message => {
        setSnackbarMessages(prev => {
          prev.delete(message);
          const fromPlayer = game.players.find(thisPlayer => thisPlayer.id === message.from);
          if (message.to === playerId && selectedPlayerId !== message.from) {
            setTimeout(() =>
              snackbarQueue.notify({
                title: <Typography use="caption">{fromPlayer && fromPlayer.name}:</Typography>,
                body: <>{message.message}</>,
                icon: 'message',
                dismissIcon: true,
                stacked: false,
              }),
            );
          }
          return new Set(prev);
        });
      });
    }

    const room = game.rooms.find(room => {
      return room.type === player.room;
    });
    if (!room) {
      return <h1>room not found</h1>;
    }

    const addTurn = (ready: boolean, bid?: Bid, airlockList?: string[]): Promise<Game> => {
      return AppSyncClient.mutateAddTurn(
        game.id,
        player.id,
        ready,
        Act.MOVE,
        nextRoom,
        airlockList ? airlockList : player.nextTurn.airlockList,
        bid,
      );
    };

    const placeBid = (index: number, amount: number, isSacrifice: boolean): void => {
      addTurn(player.ready, {
        index,
        amount,
        isSacrifice,
      }).catch(showError('Error while placing bid'));
    };

    const readyChanged = (isReady: boolean): void => {
      if (isReady !== player.ready) {
        addTurn(isReady).catch(showError('Error while setting ready status'));
      }
    };

    const setAirlockPlayer = (playerId: string, airlock: boolean): void => {
      const airlockList = new Set(player.nextTurn.airlockList);
      if (airlock) {
        airlockList.add(playerId);
      } else {
        airlockList.delete(playerId);
      }
      addTurn(player.ready, undefined, [...airlockList]);
    };

    const TurnView: React.FC = () => {
      return (
        <div className="config-panel">
          <br />

          <GameInfo game={game} />
          <br />

          <Auctions
            componentAuctions={game.componentAuctions}
            sacrificeAuctions={game.sacrificeAuctions}
            placeBid={placeBid}
            players={game.players}
            currentPlayerId={player.id}
            creditsAvailable={player.credits}
          />
          <br />

          <Location
            currentPlayer={player}
            players={game.players}
            rooms={game.rooms}
            nextRoom={nextRoom}
            setNextRoom={(room: RoomType): void => {
              readyChanged(false);
              setNextRoom(room);
            }}
          />
          <br />

          <Players currentPlayer={player} players={game.players} setAirlockPlayer={setAirlockPlayer} />
          <br />
        </div>
      );
    };

    if (game.status !== GameStatus.PLAYING) {
      return (
        <div className="config-panel">
          <div className="logo spaced">
            <Typography use="headline3">{EnumHelper.friendlyGameStatus(game.status)}!</Typography>
          </div>
          <Card>
            <div>
              {game.turnSummary && game.turnSummary.map(item => <div key={item}>{item}</div>)}
              <ListDivider />
              {player.turnSummary && player.turnSummary.map(item => <div key={item}>{item}</div>)}
            </div>
            <CardActions>
              <CardActionIcons>
                <Button
                  raised
                  onClick={(): void => {
                    // restart game
                    AppSyncClient.mutateStartGame(game.id).then(response => {
                      console.log(response);
                    });
                  }}
                >
                  Play again!
                </Button>
                <Button
                  raised
                  onClick={(): void => {
                    window.location.href = '/';
                  }}
                >
                  New Game!
                </Button>
              </CardActionIcons>
            </CardActions>
          </Card>
        </div>
      );
    } else {
      return (
        <div className="light-bg">
          <Chat
            open={chatOpen}
            setOpen={setChatOpen}
            gameId={game.id}
            playerId={player.id}
            players={game.players}
            messages={messages}
            selectedPlayerId={selectedPlayerId}
            setSelectedPlayerId={setSelectedPlayerId}
            messageDraft={messageDraft}
            setMessageDraft={setMessageDraft}
          />
          <Profile player={player} open={profileOpen} setProfileOpen={setProfileOpen} />
          <TurnSummary
            open={turnSummaryOpen}
            setTurnSummaryOpen={setTurnSummaryOpen}
            gameSummary={game.turnSummary}
            playerSummary={player.turnSummary}
          />
          <RulesDialog open={rulesOpen} setRulesOpen={setRulesOpen} gameCode={game.code} />
          <SnackbarQueue messages={snackbarQueue.messages} stacked />
          <PlayGameBar
            game={game}
            setProfileOpen={setProfileOpen}
            setRulesOpen={setRulesOpen}
            setChatOpen={setChatOpen}
            ready={player.ready}
            readyChanged={readyChanged}
          />
          <TurnView />
        </div>
      );
    }
  }
};

export { PlayGame };
