import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Logo } from '../../components/Logo';
import { SnackbarQueue, createSnackbarQueue } from '@rmwc/snackbar';
import { AppSyncClient } from '../../util/appsync';
import { Game } from '../../API';

const snackbarQueue = createSnackbarQueue();

const ConfigField: React.FC<{
  invalid: boolean;
  label: string;
  value: string;
  setter: (event: string) => void;
}> = props => {
  const invalid = props.invalid;
  const label = props.label;
  const value = props.value;
  const setter = props.setter;
  return (
    <div className="spaced">
      <TextField
        invalid={invalid}
        label={label}
        value={value}
        onChange={(event: React.FormEvent<HTMLInputElement>): void => {
          setter(event.currentTarget.value);
        }}
      />
    </div>
  );
};

const CreateGame: React.FC<{ location: { search: string } }> = props => {
  const [gameId, setGameId] = useState<string | undefined>(undefined);
  const [gameCode, setGameCode] = useState<string | undefined>(undefined);
  const [gameCodeValid, setGameCodeValid] = useState<boolean>(true);
  const [numPlayers, setNumPlayers] = useState<string>('6');
  const [numTraitors, setNumTraitors] = useState<string>('2');
  const [maxHealth, setMaxHealth] = useState<string>('100');
  const [startingDamage, setStartingDamage] = useState<string>('12');
  const [numTurns, setNumTurns] = useState<string>('15');
  const [numScans, setNumScans] = useState<string>('4');
  const [numRooms, setNumRooms] = useState<string>('8');

  const [numPlayersValid, setNumPlayersValid] = useState<boolean>(true);
  const [numTraitorsValid, setNumTraitorsValid] = useState<boolean>(true);
  const [maxHealthValid, setMaxHealthValid] = useState<boolean>(true);
  const [startingDamageValid, setStartingDamageValid] = useState<boolean>(true);
  const [numTurnsValid, setNumTurnsValid] = useState<boolean>(true);
  const [numScansValid, setNumScansValid] = useState<boolean>(true);
  const [numRoomsValid, setNumRoomsValid] = useState<boolean>(true);

  const submitGameCode = (): void => {
    if (gameCode) {
      AppSyncClient.queryGetGameIdByCode(gameCode.toLowerCase())
        .then(gameId => {
          if (gameId) {
            setGameId(gameId);
            window.location.href = `/play?gameId=${gameId}`;
          } else {
            setGameCodeValid(false);
            setGameCode('');
          }
        })
        .catch(error => {
          console.log('error submitting game code:');
          console.log(error);
          setGameCodeValid(false);
          setGameCode('');
          snackbarQueue.notify({
            title: <Typography use="caption">Invalid game code</Typography>,
            body: 'Please try again',
            icon: 'pan_tool',
            dismissIcon: true,
            stacked: false,
          });
        });
    }
  };

  const validateInput = ():
    | {
        validMinPlayers: number;
        validNumCrew: number;
        validNumTraitors: number;
        validMaxHealth: number;
        validStartingDamage: number;
        validNumTurns: number;
        validNumScans: number;
        validNumRooms: number;
      }
    | undefined => {
    setNumPlayersValid(true);
    setNumTraitorsValid(true);
    setMaxHealthValid(true);
    setStartingDamageValid(true);
    setNumTurnsValid(true);
    setNumScansValid(true);
    setNumRoomsValid(true);

    const validMinPlayers = parseInt(numPlayers);
    const validNumTraitors = parseInt(numTraitors);
    const validMaxHealth = parseInt(maxHealth);
    const validStartingDamage = parseInt(startingDamage);
    const validNumTurns = parseInt(numTurns);
    const validNumScans = parseInt(numScans);
    const validNumRooms = parseInt(numRooms);

    let allInputValid = true;

    if (!validMinPlayers || validMinPlayers < 1) {
      allInputValid = false;
      setNumPlayersValid(false);
    }
    if (!validNumTraitors || validNumTraitors < 1) {
      allInputValid = false;
      setNumTraitorsValid(false);
    }
    if (!validMaxHealth || validMaxHealth < 1) {
      allInputValid = false;
      setMaxHealthValid(false);
    }
    if (!validStartingDamage || validStartingDamage < 0) {
      allInputValid = false;
      setStartingDamageValid(false);
    }
    if (!validNumTurns || validNumTurns < 1) {
      allInputValid = false;
      setNumTurnsValid(false);
    }
    if (!validNumScans || validNumScans < 0) {
      allInputValid = false;
      setNumScansValid(false);
    }
    if (!validNumRooms || validNumRooms < 2) {
      allInputValid = false;
      setNumRoomsValid(false);
    }

    if (!allInputValid) {
      return undefined;
    } else {
      return {
        validMinPlayers,
        validNumCrew: validMinPlayers - validNumTraitors,
        validNumTraitors,
        validMaxHealth,
        validStartingDamage,
        validNumTurns,
        validNumScans,
        validNumRooms,
      };
    }
  };

  const doCreateGame = (): void => {
    const validInput = validateInput();

    if (validInput) {
      AppSyncClient.mutateCreateGame({
        numCrew: validInput.validNumCrew,
        numTraitors: validInput.validNumTraitors,
        initialDistanceToHome: 200,
        initialDistanceFromPirates: 100,
        numComponentAuctions: 3,
        numSacrificeAuctions: 2,
      })
        .then((game: Game) => {
          console.log('new game:');
          console.log(game);
          setGameId(game.id);
        })
        .catch(error => {
          console.log(`ERROR: ${JSON.stringify(error)}`);
          snackbarQueue.notify({
            title: <Typography use="caption">There was an error, you may need to reload the page:</Typography>,
            body: <>{JSON.stringify(error)}</>,
            icon: 'error',
            dismissIcon: true,
            stacked: false,
          });
        });
    }
  };

  return (
    <div className="config-panel">
      <SnackbarQueue messages={snackbarQueue.messages} stacked />
      <div className="logo spaced">
        <Logo />
      </div>
      <div className="spaced centered">
        <Card>
          <TextField
            label="game code..."
            value={gameCode}
            invalid={!gameCodeValid}
            onChange={(event: React.FormEvent<HTMLInputElement>): void => {
              setGameCode(event.currentTarget.value.toUpperCase());
            }}
            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>): void => {
              event.which === 13 && submitGameCode();
            }}
            trailingIcon={{
              icon: 'play_arrow',
              tabIndex: 0,
              onClick: submitGameCode,
            }}
          />
        </Card>
      </div>

      <div style={{ color: 'white', marginBottom: '4px' }}>or</div>

      <div className="spaced centered">
        <Card>
          <Typography use="headline6">Create new game</Typography>
          <ConfigField invalid={!numPlayersValid} label="# players" value={numPlayers} setter={setNumPlayers} />
          <ConfigField label="# traitors" invalid={!numTraitorsValid} value={numTraitors} setter={setNumTraitors} />
          <ConfigField label="Ship health" value={maxHealth} invalid={!maxHealthValid} setter={setMaxHealth} />
          <ConfigField
            label="Starting damage"
            value={startingDamage}
            invalid={!startingDamageValid}
            setter={setStartingDamage}
          />
          <ConfigField label="# turns until home" invalid={!numTurnsValid} value={numTurns} setter={setNumTurns} />
          <ConfigField label="# scans" invalid={!numScansValid} value={numScans} setter={setNumScans} />
          <ConfigField label="# rooms" invalid={!numRoomsValid} value={numRooms} setter={setNumRooms} />
          <Button raised onClick={(): void => doCreateGame()}>
            Create
          </Button>
        </Card>
      </div>

      <div className="feedback-info">
        Have feedback? <a href="mailto:support@moonfetus.com">support@moonfetus.com</a>
      </div>
      <div className="coil-info">
        <a href="https://coil.com">
          <img alt="coil C" src="coil-c.png" /> Coil
        </a>{' '}
        enabled
      </div>
      {gameId && <Redirect to={`/addPlayer?gameId=${gameId}`} />}
    </div>
  );
};

export { CreateGame };
