import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import gql from 'graphql-tag';
import { CompressionHelper } from '../compression-helper';
import { addPlayer, addTurn, createGame, createMessage, startGame } from '../../graphql/mutations';
import { updatedGame, newMessage } from '../../graphql/subscriptions';
import { getGame, getMessages, getGameIdFromCode } from '../../graphql/queries';
import {
  Act,
  AddPlayerMutation,
  AddPlayerMutationVariables,
  AddTurnMutation,
  AddTurnMutationVariables,
  CreateGameMutation,
  CreateGameMutationVariables,
  CreateMessageMutation,
  CreateMessageMutationVariables,
  GameConfig,
  Game,
  GetGameQuery,
  GetGameQueryVariables,
  GetMessagesQuery,
  GetMessagesQueryVariables,
  NewMessageSubscription,
  NewMessageSubscriptionVariables,
  RoomType,
  StartGameMutation,
  StartGameMutationVariables,
  UpdatedGameSubscription,
  UpdatedGameSubscriptionVariables,
  Bid,
  GetGameIdFromCodeQueryVariables,
  GetGameIdFromCodeQuery,
} from '../../API';

import prodAwsconfig from '../../aws-exports';
import devAwsconfig from '../../aws-exports.dev';

// FIXME: need a better way to manage api config per env
let awsconfig = devAwsconfig;
if (process.env.VACUUM_ENV === 'Prod') {
  awsconfig = prodAwsconfig;
}

const client = new AWSAppSyncClient({
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.API_KEY,
    apiKey: awsconfig.aws_appsync_apiKey,
  },
  disableOffline: true,
});

const mutateCreateGame = (config: GameConfig): Promise<Game> => {
  const variables: CreateGameMutationVariables = {
    config,
  };
  return client
    .mutate({
      mutation: gql(createGame),
      variables,
    })
    .then((response: { data: CreateGameMutation }) => {
      return CompressionHelper.decompressGame(response.data.createGame);
    });
};

const mutateStartGame = (gameId: string): Promise<Game> => {
  const variables: StartGameMutationVariables = {
    gameId,
  };
  return client
    .mutate({
      mutation: gql(startGame),
      variables,
    })
    .then((response: { data: StartGameMutation }) => {
      return CompressionHelper.decompressGame(response.data.startGame);
    });
};

const mutateAddTurn = (
  gameId: string,
  playerId: string,
  ready: boolean,
  nextAct: Act,
  nextRoom: RoomType | undefined,
  airlockList: string[],
  bid?: Bid,
): Promise<Game> => {
  const variables: AddTurnMutationVariables = {
    gameId,
    playerId,
    ready,
    nextTurn: {
      nextAct,
      nextRoom,
      airlockList,
      bid,
    },
  };
  return client
    .mutate({
      mutation: gql(addTurn),
      variables,
    })
    .then((response: { data: AddTurnMutation }) => {
      return CompressionHelper.decompressGame(response.data.addTurn);
    });
};

const mutateAddPlayer = (gameId: string, playerName: string, sessionId: string): Promise<Game> => {
  const variables: AddPlayerMutationVariables = {
    gameId,
    playerName,
    sessionId,
  };
  return client
    .mutate({
      mutation: gql(addPlayer),
      variables,
    })
    .then((response: { data: AddPlayerMutation }) => {
      return CompressionHelper.decompressGame(response.data.addPlayer);
    });
};

const queryGetGame = (id: string): Promise<Game> => {
  const variables: GetGameQueryVariables = {
    id,
  };
  return client
    .query({
      query: gql(getGame),
      variables,
    })
    .then(response => {
      const response2 = response as { data: GetGameQuery };
      return CompressionHelper.decompressGame(response2.data.getGame);
    });
};

const queryGetGameIdByCode = (code: string): Promise<string> => {
  const variables: GetGameIdFromCodeQueryVariables = {
    code,
  };
  return client
    .query({
      query: gql(getGameIdFromCode),
      variables,
    })
    .then(response => {
      const response2 = response as { data: GetGameIdFromCodeQuery };
      return response2.data.getGameIdFromCode;
    });
};

const subscribeUpdatedGame = (gameId: string, next: (data: Game) => void, error: (error: object) => void): void => {
  const variables: UpdatedGameSubscriptionVariables = {
    id: gameId,
  };
  client
    .subscribe({
      query: gql(updatedGame),
      variables,
    })
    .subscribe({
      next: (response: { data: UpdatedGameSubscription }) => {
        if (response.data.updatedGame) {
          next(CompressionHelper.decompressGame(response.data.updatedGame));
        }
      },
      error,
    });
};

const queryGetMessages = (gameId: string): Promise<{ data: GetMessagesQuery }> => {
  const variables: GetMessagesQueryVariables = {
    gameId,
  };
  return client.query({
    query: gql(getMessages),
    variables,
  });
};

const subscribeNewMessage = (
  gameId: string,
  next: (data: { data: NewMessageSubscription }) => void,
  error: (error: object) => void,
): void => {
  const variables: NewMessageSubscriptionVariables = {
    gameId,
  };
  client
    .subscribe({
      query: gql(newMessage),
      variables,
    })
    .subscribe({
      next,
      error,
    });
};

const mutateCreateMessage = (
  gameId: string,
  to: string,
  from: string,
  message: string,
): Promise<{ data: CreateMessageMutation }> => {
  const variables: CreateMessageMutationVariables = {
    gameId,
    to,
    from,
    message,
  };
  return client.mutate({
    mutation: gql(createMessage),
    variables,
  });
};

const AppSyncClient = {
  mutateAddPlayer,
  mutateAddTurn,
  mutateCreateGame,
  mutateCreateMessage,
  mutateStartGame,
  queryGetGame,
  queryGetGameIdByCode,
  queryGetMessages,
  subscribeNewMessage,
  subscribeUpdatedGame,
};

export { AppSyncClient };
