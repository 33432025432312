import React from 'react';

import { MessagesView } from '../MessagesView';
import { Drawer, DrawerContent } from '@rmwc/drawer';
import { GetMessagesQuery, Player } from '../../API';

type Message = GetMessagesQuery['getMessages']['messages'][0];

const Chat: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  gameId: string;
  playerId: string;
  players: Player[];
  messages: Message[];
  selectedPlayerId: string;
  setSelectedPlayerId: (id: string) => void;
  messageDraft: string;
  setMessageDraft: (id: string) => void;
}> = props => {
  const {
    open,
    setOpen,
    gameId,
    playerId,
    players,
    messages,
    selectedPlayerId,
    setSelectedPlayerId,
    messageDraft,
    setMessageDraft,
  } = props;

  return (
    <Drawer modal open={open} onClose={(): void => setOpen(false)}>
      <DrawerContent>
        <MessagesView
          gameId={gameId}
          playerId={playerId}
          players={players}
          messages={messages}
          selectedPlayerId={selectedPlayerId}
          setSelectedPlayerId={setSelectedPlayerId}
          messageDraft={messageDraft}
          setMessageDraft={setMessageDraft}
        />
      </DrawerContent>
    </Drawer>
  );
};

export { Chat };
